/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { FeatureMapService } from '../../../../libs/dash/guards/feature-map/feature-map.service';
import { TranslateInitializer } from '@libs/shared/modules/i18n';

export function appInitializerFactory(fm: FeatureMapService, translateInitializer: TranslateInitializer) {
	return async () => {
		await Promise.all([translateInitializer.get(), fm.loadFeatureMap()]);
		//updated values from config goes here
		// Updating provider value after the app has loaded
		return;
	};
}
